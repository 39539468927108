import http from '../../../../libs/http'

export default class ExpenseServices {
  urlPettyCash = process.env.VUE_APP_MICROSERVICE_API_PETTY_CASH

  async getProveedores (name) {
    return await http.get(this.urlPettyCash + '/proveedores/paginate', { params: { name } }).then(res => res.data).catch(error => {
      throw error
    })
  }
}
