<template>
  <section class="container box p-5">
    <div class="flex justify-between">
      <div class="flex text-base font-medium text-primary-1 dark:text-white">
        <span class="w-6" v-if="$route.name==='pharmasan.contabilidad.caja-menor.ver-caja-menor.egresos.editar'"><font-awesome-icon icon='pen'/></span>
        <span class="w-6" v-else><font-awesome-icon icon='plus'/></span>
        <span
          class="p-input-icon-left">{{ $route.name === 'pharmasan.contabilidad.caja-menor.ver-caja-menor.egresos.editar' ? 'Editar Egreso' : 'Agregar Egreso' }}</span>
      </div>
      <div class="">
        <back-button/>
      </div>
    </div>

    <ExpenseCreate/>

  </section>
</template>

<script>
import { defineComponent } from 'vue'
import ExpenseCreate from '../../components/expenseCreate.vue'

export default defineComponent({
  components: {
    ExpenseCreate
  }
})
</script>
