<template>
  <form v-on:submit="addExpense">
    <div class="flex mt-5">
      <div class="flex-1 mr-2">
        <label class="form-label font-bold"
        >Tipo de Egreso</label
        >
        <Dropdown class="w-full" v-model="formInputs.expenseType" :options="typesExpenses" optionLabel="name"
                  optionValue="value" placeholder="Seleccione una opcion"/>
        <MessageError text=""/>
      </div>

      <div class="flex-1 mr-2">
        <label for="applicant1" class="form-label font-bold">Proveedor:</label>
        <AutoComplete v-model="labelProveedor" :suggestions="filteredProveedor" @complete="searchProveedor($event)"
                      field="CardName"
                      @item-select="onChangeProveedor"
                      inputClass="w-full"
                      class="w-full"
        />
      </div>

      <div class="flex-1" v-if="formInputs.expenseType === 'Prestamos'">
        <label for="applicant1" class="form-label font-bold">Entregado A:</label>
        <input
          type="text"
          class="form-control"
          id="applicant1"
          v-model="formInputs.applicant"
        />
      </div>

      <div
        class="flex-1"
        v-else-if="formInputs.expenseType === 'Reembolso Caja Sede'"
      >
        <label for="office" class="form-label font-bold">Sede:</label>
        <input
          type="text"
          class="form-control"
          id="office"
          v-model="formInputs.office"
          required
        />
      </div>

      <div class="flex-1" v-else>
        <template v-if="formInputs.expenseType === 'Anticipo'">
          <label for="applicant2" class="form-label font-bold">Socio:</label>
          <input
            type="text"
            class="form-control"
            id="applicant2"
            v-model="formInputs.applicant"
          />
        </template>
        <template v-else>
          <label for="applicant3" class="form-label font-bold">Area:</label>
          <input
            type="text"
            class="form-control"
            id="applicant3"
            v-model="formInputs.applicant"
            required
          />
        </template>
      </div>
    </div>

    <div class="flex w-full mt-2 gap-2">
      <div class="flex flex-col w-full">
        <label class="form-label font-bold">Tipo</label>
        <Dropdown v-model="formInputs.Series" :options="tiposFacturas" optionLabel="name" optionValue="code"
                  placeholder="Seleccione un tipo"/>
      </div>
      <div class="flex flex-col w-full">
        <label class="form-label font-bold">Clase</label>
        <Dropdown v-model="formInputs.DocType" :options="clasesFacturas" optionLabel="name" optionValue="code"
                  placeholder="Seleccione una clase"/>
      </div>
      <div class="flex flex-col w-full">
        <label class="form-label font-bold">N° Ref del Acreedor</label>
        <input type="text" class="form-control" v-model="formInputs.NumAtCard" required/>
      </div>
    </div>

    <div class="w-full mt-2">
      <div v-if="formInputs.expenseType !== 'Prestamos'">
        <label class="form-label font-bold">Concepto</label>
        <input type="text" class="form-control" v-model="formInputs.concept" required/>
      </div>
      <div v-else></div>
    </div>

    <div class="flex flex-wrap mt-2">
      <div class="flex-1 mr-2" v-if="formInputs.expenseType === 'Anticipo'">
        <label class="form-label font-bold">Cuenta Contable</label>
        <input
          type="text"
          class="form-control"
          v-model="formInputs.accountingAccount"
          required
        />
      </div>
      <div class="flex-1 mr-2" v-if="formInputs.expenseType === 'Anticipo'">
        <label class="form-label font-bold"
        >Norma de Reparto</label
        >
        <input
          type="text"
          class="form-control"
          v-model="formInputs.distributionRule"
        />
      </div>
    </div>

    <div class="w-full mt-2">
      <label for="amount" class="form-label font-bold">Valor</label>
      <input id="amount" type="number" class="form-control" v-model="formInputs.amount" required/>
    </div>

    <div class="flex w-full mt-2 gap-2">
      <div class="flex flex-col w-full">
        <label class="form-label font-bold">Tipo</label>
        <Dropdown v-model="formInputs.Series" :options="tiposFacturas" optionLabel="name" optionValue="code"
                  placeholder="Seleccione un tipo"/>
      </div>
      <div class="flex flex-col w-full">
        <label class="form-label font-bold">Clase</label>
        <Dropdown v-model="formInputs.DocType" :options="clasesFacturas" optionLabel="name" optionValue="code"
                  placeholder="Seleccione una clase"/>
      </div>
      <div class="flex flex-col w-full">
        <label class="form-label font-bold">N° Ref del Acreedor</label>
        <input type="text" class="form-control" v-model="formInputs.NumAtCard" required/>
      </div>
    </div>
    <div class="flex flex-col w-full mt-2 gap-2">
      <label class="form-label font-bold">Items</label>
      <DataTable :value="itemsFactura" class="w-full p-datatable-sm" responsiveLayout="scroll">
        <template #empty>
          No hay datos para mostrar
        </template>
        <template #header>
          <div class="flex justify-end">
            <Button
              label="Agregar Item"
              icon="pi pi-plus"
              class="p-button-sm p-4 mr-2 p-button-success"
              @click="showDialogItem = true"
            />
          </div>
        </template>
        <Column field="ItemCode" header="ItemCode"></Column>
        <Column field="Quantity" header="Quantity"></Column>
        <Column field="UnitPrice" header="UnitPrice"></Column>
        <Column field="TaxCode" header="TaxCode"></Column>
      </DataTable>
    </div>

    <div class="flex justify-end mt-5 ml-5">
      <div v-if="$route.name === 'pharmasan.contabilidad.caja-menor.ver-caja-menor.egresos.editar'">
        <Button
          label="Guardar"
          icon="pi pi-save"
          class="p-button-sm p-4 mr-2"
          @click="editExpense"
        />
      </div>
      <div v-else>
        <Button
          label="Agregar Egreso"
          icon="pi pi-plus"
          class="p-button-sm p-4 mr-2"
          type="submit"
        />
      </div>
      <Button
        label="Cancelar"
        class="p-button-sm p-4 bg-gray-700 border-b border-gray-200"
        @click="this.$router.back()"
      />
    </div>
  </form>

  <Dialog header="Agregar Item" v-model:visible="showDialogItem" :breakpoints="{'960px': '75vw', '640px': '90vw'}"
          :style="{width: '50vw'}" :modal="true">
    <form v-on:submit="actionDialogItem" class="flex flex-col">
      <div class="flex">
        <div class="flex flex-col">
          <label class="form-label font-bold">Item:</label>
          <AutoComplete v-model="labelProveedor" :suggestions="filteredProveedor" @complete="searchProveedor($event)"
                        field="CardName"
                        @item-select="onChangeProveedor"
                        inputClass="w-full"
                        class="w-full"
          />
        </div>
        <div class="flex flex-col">
          <label class="form-label font-bold">Item:</label>
          <InputText v-model="formDialogItem.ItemCode" class="w-full"/>
        </div>
      </div>
    </form>
    <template #footer>
      <Button label="No" icon="pi pi-times" @click="showDialogItem = false" class="p-button-text"/>
      <Button label="Yes" icon="pi pi-check" type="submit" autofocus/>
    </template>
  </Dialog>
</template>

<script>
import { reactive, computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import useExpense from '../hooks/useExpense'
import { messageSuccess } from '@/libs/mensajes'
import ProveedoresServices from '../services/proveedoresServices'
import * as yup from 'yup'
import { useField, useForm } from 'vee-validate'

export default {
  setup () {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const { typesExpenses, createExpenses, getExpenses, updateExpenses } = useExpense()
    const filteredProveedor = ref()
    const _ProveedoresServices = new ProveedoresServices()
    const labelProveedor = ref('')
    const clasesFacturas = [
      { name: 'Articulo', code: 'dDocument_Items' },
      { name: 'Servicio', code: 'dDocument_Service' }
    ]
    const tiposFacturas = [
      { name: 'FC', code: 14 },
      { name: 'DocSopor', code: 154 }
    ]
    const itemsFactura = ref([])
    const showDialogItem = ref(false)
    const schema = yup.object({
      ItemCode: yup.string().required('El campo es requerido').label(''),
      Quantity: yup.number().required('El campo es requerido'),
      UnitPrice: yup.number().required('El campo es requerido').label(''),
      TaxCode: yup.string().required('El campo es requerido')
    })
    const { errors, values: formDialogItem, handleSubmit } = useForm({
      validationSchema: schema
    })
    useField('ItemCode')
    useField('Quantity')
    useField('UnitPrice')
    useField('TaxCode')

    let findExpense = {}

    const getStoreDate = computed(() => store.getters['storePettyCash/getStoreDate'])

    if (route.name === 'pharmasan.contabilidad.caja-menor.ver-caja-menor.egresos.editar') {
      const storeExpense = computed(
        () => store.getters['storePettyCash/getExpenses']
      )
      findExpense = storeExpense.value.rows.find(
        (item) => item.id === parseInt(route.params.id)
      )
    }

    const formInputs = reactive({
      expenseType: findExpense ? findExpense.expenseType : null,
      numberDoc: findExpense ? findExpense.numberDoc : null,
      concept: findExpense ? findExpense.concept : null,
      applicant: findExpense ? findExpense.applicant : null,
      office: findExpense ? findExpense.office : null,
      accountingAccount: findExpense ? findExpense.accountingAccount : null,
      distributionRule: findExpense ? findExpense.distributionRule : null,
      amount: findExpense ? findExpense.amount : null,
      proveedor: findExpense ? findExpense.proveedor : null,
      Series: findExpense ? findExpense.Series : null,
      DocType: findExpense ? findExpense.DocType : null,
      NumAtCard: findExpense ? findExpense.NumAtCard : null
    })

    const addExpense = async (e) => {
      try {
        e.preventDefault()
        const result = await createExpenses(formInputs).catch(err => {
          throw err
        })
        if (!result) {
          throw result
        }
        messageSuccess('Operación exitosa!')
        if (formInputs.expenseType === 'Factura') {
          console.log('es una factura')
        } else {
          router.back()
        }
      } catch (err) {
        // errorHtml(err)
        console.error('Error al crear egreso', err)
      }
    }
    const editExpense = async (e) => {
      e.preventDefault()
      try {
        await updateExpenses(formInputs, route.params.id)
        // GET DATA INCOME
        await getExpenses({ date: getStoreDate.value })
        messageSuccess('Operación exitosa!')
        router.back()
      } catch (err) {
        // errorHtml(err)
        console.error('Error al editar egreso', err)
      }
    }

    const searchProveedor = (e) => {
      _ProveedoresServices.getProveedores(e.query).then((res) => {
        if (res.length === 0) {
          filteredProveedor.value = [{ CardName: 'No se encontraron resultados' }]
        } else {
          filteredProveedor.value = res
        }
      })
    }

    const onChangeProveedor = ({ value }) => {
      formInputs.proveedor = value.CardCode
      labelProveedor.value = value.CardName
    }

    const actionDialogItem = handleSubmit((values) => {
      console.log(values)
      showDialogItem.value = false
    })

    return {
      typesExpenses,
      formInputs,
      filteredProveedor,
      labelProveedor,
      tiposFacturas,
      clasesFacturas,
      itemsFactura,
      showDialogItem,
      errors,
      formDialogItem,
      addExpense,
      editExpense,
      searchProveedor,
      onChangeProveedor,
      actionDialogItem
    }
  }
}
</script>
